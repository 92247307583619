

$('document').ready(function () {

	/*audiojs.events.ready(function() {
	    var as = audiojs.createAll();
	  });*/

	$(function() {
	    FastClick.attach(document.body);
	});

	$('.js-burger').click(function(){
		$('.js-mobile').addClass('is-open');		
	});

	$('.js-mobile-close').click(function(){
		$('.js-mobile').removeClass('is-open');		
	});

	$('.js-javascript-warning__btn').click(function(){
		$('.js-javascript-warning').addClass('is-hidden');
	});

	window.closeModal = function(){
	    //$('.js-overlay').removeClass('is-open');
	    //$('.js-activity').css("display","none");

	    /*if (activity) {
	    	activity.hideActivity();
	    }*/

	    var lastsegment = document.querySelector('meta[property="lastsegment"]').getAttribute("content");
	    var firstsegment = document.querySelector('meta[property="firstsegment"]').getAttribute("content");


	    //First check whether we are in kids area

	    if (firstsegment == 'kids') {

	    	//Check if we are on pride place map
	    	if (lastsegment == 'pride-place') {
	    	
		    	//If the book is loaded and activity is being played
		    	if (book.showActivity) {
		    		
		    		book.hideActivity();
		    	}
		    	else{//Therefore no book is currently loaded
		    		activity.hideActivity();
		    	}	
		    }

		    //Check if we are on activites/quiz page

		    if (lastsegment == 'activities-quizzes') {
		    	activity.hideActivity();
		    }

		    //Check if we are on books page

		    if (lastsegment == 'books'){
		    	book.hideActivity();
		    }
	    }

	    else {
	    	//If the book is loaded and activity is being played
	    	if (book.showActivity) {
	    		
	    		book.hideActivity();
	    	}
	    	else{//Therefore no book is currently loaded
	    		activity.hideActivity();
	    	}	
	    }

	    /*
	    if (lastsegment == 'pride-place') {
	    	
	    	
	    	//If the book is loaded and activity is being played
	    	if (book.showActivity) {
	    		
	    		book.hideActivity();
	    	}
	    	else{//Therefore no book is currently loaded
	    		activity.hideActivity();
	    	}
	    	

	    	
	    }
	    else if (lastsegment == 'activities-quizzes'){
	    	activity.hideActivity();
	    }
	    else if (lastsegment == 'books'){
	    	book.hideActivity();
	    }
	    else{
	    	if (firstsegment == 'teachers' || firstsegment == 'family-learning') {
	    		book.hideActivity()
	    	}
	    	else{
	    		activity.hideActivity();
	    	}
	    	
	    }
	    */
	    
	};

});


